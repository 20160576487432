.form-createPost_modal {
}

.create-post_modal {
  padding: 0;
}

.form-createPost_header {
  border-bottom: 1px solid #ccc;
}

.loading-createPost {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  transform: translate(-50%, -50%);
}

#scrollableDiv {
  max-height: 390px !important;
  overflow: auto;
}

#scrollableFriendTagSelection {
  max-height: 390px !important;
  overflow: auto;
}

#scrollableFriendTagSelection {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

#scrollableFriendTagSelection:hover {
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

#scrollableDiv {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

#scrollableDiv:hover {
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

// .toast-danger {
//   visibility: hidden;
//   opacity: 0;
//   transition: visibility 0s, opacity 0.5s linear;
// }
// .toast-danger_trigger {
//   visibility: visible;
//   opacity: 1;
// }

