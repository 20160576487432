.header-friend_edit {
  bottom: 35px;
}

.friends-menu {
  top: -35px;
}

.pending-friendsCount_icon {
  top: 17px;
  right: -11px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
