.image_video {
    object-fit: cover;
}


.play-btn {
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 4;
    display: block;
    width: 32px;
    height: 32px;
    margin: 15px 0 0 -17px;
    font-size: 2em;
    line-height: 64px;
    background-image: url(../../../assets/images/icon/play.svg);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    visibility: visible;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    cursor: pointer;
    }

    .thumbnail_video {
        height: 18vh;
    }

    @media screen and (max-width: 450px) {
        .mess-created-at{
            width: 55px !important;
        }
    }