.sumaryUserReaction {
  z-index: 99999;
  min-height: 150px;
  min-width: 150px;
  position: absolute;
  top: 35px;
  left: 0;
  background-color: rgba(75, 85, 99, 0.9);
}

.listUserReactionModel-content {
  
}

.loading-userReaction {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.listUserReactionModel {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.listUserReactionModel-container {
  // z-index: 99999;
  // left: -500px;
  // top: 0;
  // width: 999%;
  // min-height: 100%;
  // height: auto !important;
  // height: 100%;
}

.body_modal {
  overflow: hidden;
  /* disable scrolling */
}

ul > li {
  height: fit-content;
}

.line-clamp {
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  &.line-clamp-1 {
    -webkit-line-clamp: 1;
  }

  &.line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  &.line-clamp-3 {
    -webkit-line-clamp: 3;
  }
}
