.conversation-group_thumbnail {
  // left: -0.5rem;
  height: 120px;
  width: 120px;
}
.container-messenger_sharingPhoto {
  background-color: #f5f5f5;
}

.conversation-group_img1 {
  left: -12%;
}

.conversation-group_img2 {
  left: 18%;
}

.conversation-group_img3 {
  left: 49%;
}
.avatar-border_default {
  border: 2px solid #fff;
}
.avatar-shadow_verify {
  box-shadow: 0px 0px 6px 1px rgb(255, 162, 0);
  -webkit-box-shadow: 0px 0px 6px 1px rgb(255, 162, 0);
  -moz-box-shadow: 0px 0px 6px 1px rgb(255, 162, 0);
}
