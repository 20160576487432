@tailwind base;
@tailwind components;
@tailwind utilities;

.primaryColor {
  color: #05a2ab;
}

.bgPrimaryColor {
  background-color: #05a2ab;
}

.container {
  max-width: 660px;
}

.profileImage {
  background-position: center;
  background-size: cover;
}

hr.solid {
  border-top: 2px solid #f0f2f5;
}

.border-primary-color {
  border-color: #05a2ab;
}

.line-clamp {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-box-orient: vertical;

  &.line-clamp-1 {
    -webkit-line-clamp: 1;
  }

  &.line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  &.line-clamp-3 {
    -webkit-line-clamp: 3;
  }
}

.w-header-search {
  input:focus-visible {
    outline: 0;
  }

  input::-webkit-search-cancel-button {
    position: relative;
    width: 20px;
    height: 20px;
    color: #fff;
    // background-color: rgb(197, 193, 193);
    border-radius: 10px;
    -webkit-appearance: none;
  }

  width: 100%;
  max-width: 500px;
  min-width: 300px;
}

.search-tab-item {
  cursor: pointer;
}

.shadow_verify {
  box-shadow: 0 0 2px rgb(248, 241, 228), 0px 0px 2px 2px rgb(255, 181, 53), 0px 0px 2px 6px rgb(248 229 194 / 69%);

  -webkit-box-shadow: 0 0 2px rgb(248, 241, 228), 0px 0px 2px 2px rgb(255, 181, 53), 0px 0px 2px 6px rgb(248 229 194 / 69%);

  -moz-box-shadow: 0 0 2px rgb(248, 241, 228), 0px 0px 2px 2px rgb(255, 181, 53), 0px 0px 2px 6px rgb(248 229 194 / 69%);
}
.shadow_verify_lg {
  box-shadow: 0 0 5px rgb(248, 241, 228), 0px 0px 2px 4px rgb(255, 181, 53), 0px 0px 2px 9px rgb(248 229 194 / 69%);

  -webkit-box-shadow: 0 0 5px rgb(248, 241, 228), 0px 0px 2px 4px rgb(255, 181, 53), 0px 0px 2px 9px rgb(248 229 194 / 69%);

  -moz-box-shadow: 0 0 5px rgb(248, 241, 228), 0px 0px 2px 4px rgb(255, 181, 53), 0px 0px 2px 9px rgb(248 229 194 / 69%);
}

.animate-none {
  animation: none;
}

.animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.bd-30 {
  border-radius: 30px;
}

.comment-input {
  &:focus:not(:focus-visible) {
    /* Thêm các style bạn muốn áp dụng cho focus */
  }
}

.media-upload {
  display: flex;
  position: relative;
  margin: 5px;
  flex: 0 0 auto;

  .remove-media {
    position: absolute;
    border: 1px solid #fff;
    top: 10px;
    right: 10px;
    background: #e5e7eb;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
  }
}

.tags-users {
  position: relative;
  margin: 5px;
  margin-left: 10px;
  flex: 0 0 auto;
  text-align: center;

  .remove-user {
    position: absolute;
    border: 1px solid #fff;
    top: -5px;
    right: -5px;
    background: #e5e7eb;
    border-radius: 50%;
    padding: 3px;

    cursor: pointer;
  }
}

.model-dropdown {
  position: absolute;
  top: -10px;
  left: -160px;
}

// @-webkit-keyframes slideInUp {
//   0% {
//     -webkit-transform: translate3d(0, 100%, 0);
//     transform: translate3d(0, 100%, 0);
//     visibility: visible
//   }

//   to {
//     -webkit-transform: translateZ(0);
//     transform: translateZ(0)
//   }
// }

// @keyframes slideInUp {
//   0% {
//     -webkit-transform: translate3d(0, 100%, 0);
//     transform: translate3d(0, 100%, 0);
//     visibility: visible
//   }

//   to {
//     -webkit-transform: translateZ(0);
//     transform: translateZ(0)
//   }
// }

.main-header {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  -webkit-transition: top .3s ease;
  -o-transition: top .3s ease;
  transition: top .3s ease;
  animation-name: slideInUp;
}

.loading-extra {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.avartar-border_default {
  border: solid 1px #ccc;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}