.header_video {
    bottom: 80px;
}
.icon_play {
    position: absolute;
    top: 50%;
}
.box_action {
    width: 100%;
}
.box_content_video {
    width: 40%;
}
.container_video {
    .play-btn {
        position: absolute;
        top: 42%;
        left: 50%;
        z-index: 4;
        display: block;
        width: 32px;
        height: 32px;
        margin: 15px 0 0 -17px;
        font-size: 2em;
        line-height: 64px;
        background-image: url(../../assets/images/icon/play.svg);
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: cover !important;
        background-size: cover !important;
        visibility: visible;
        cursor: pointer;
        opacity: 1;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        cursor: pointer;
        }
}

.thumnail_video {
        // height: 70vh;
        // width: 50%;
        width: 100%;
    height: 50vh;
    object-fit: cover;
 }

 .no-scroll {
    overflow: hidden;
 }

 .shadow_verify {
    box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
    -webkit-box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
    -moz-box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
  }
  .box_comment {
    width: 100%;
  }
  .camera {
    right: 4%;
    top: 23%;
}
