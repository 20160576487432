html {
  scroll-snap-type: y mandatory;
}
.content_video {
  height: 100vh;
  .video-container-auto {
    .data-vjs-player {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      align-items: center;
      max-width: 50% !important;
      max-height: 0% !important;
    }
  }
  .btn_close {
    right: 10%;
    top: 3%;
  }
}
.shadow_verify {
  box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
  -webkit-box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
  -moz-box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
}

.summaryDislikeReaction {
  left: -10px !important;
}
