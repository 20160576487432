@media screen and (max-width: 1030px) {
    .login-page-content{
        justify-content: center !important;
        .content-left{
            display: none;
        }
        .content-right{
            width: 100%;
            .content-right-item{
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .login-page{
        background: white !important;
        .content-right-item-content{
            padding: 10px !important;
        }
    }
}