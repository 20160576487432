@media screen and (max-width: 490px) {
    .list-group-item{
        .group-item{
            display: block !important;
            .group-item-right{
                padding-top: 8px;
            }
        }
    }
    
}