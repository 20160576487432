.count_mess {
   right: 40%;
}

@keyframes navHide {
   0% {
      top: 0px;
   }

   100% {
      top: -164px;
   }
}

@keyframes navAppear {
   0% {
      top: -164px;
   }

   100% {
      top: 0px;
   }
}

.nav-hide {

   animation-name: navHide;
   animation-duration: 2s;
   top: -164px !important;
}

.nav-appear {
   animation-name: navAppear;
   animation-duration: 2s;
   top: 0 !important;
}

.hidden-on-screen-mobile-logo{
   display: none;
}
.content-text-message{
   height: calc(100vh - 154px) !important;
   overflow: auto;
}

@media screen and (max-width: 1300px) {
   .container-content{
      justify-content: center !important;
      gap: 40px;
      .container-content-left{
         display: none !important;
      }
   }
   .hidden-on-screen-tablet-logo{
      display: none !important; 
   }
   .hidden-on-screen-mobile-logo{
      display: block;
   }
   .header-menu{
      justify-content: center !important;
      gap: 40px;
   }
   
}

@media screen and (max-width: 1050px) {
   .main-header{
      max-width: 660px;
      margin: 0 auto;
   }
   .header-menu{
      justify-content: center !important;
      .hidden-on-screen-tablet{
         display: none !important; 
      }
   }
   .show-on-screen-tablet{
      display: flex !important;
   }
   .container-content{
      gap: 0px;
      .container-content-right{
         display: none !important;
      }
   }
   .hidden-on-screen-mobile-logo{
      display: none;
   }

   .content-text-message{
      height: calc(100vh - 209px) !important;
   }
}

