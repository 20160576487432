.input {
  border: none;
  // border-bottom: 1px solid rgb(238, 238, 238);
  padding: 5px 70px;
  outline: none;
}

.input_phone {
  border: none;
  // border-bottom: 1px solid rgb(238, 238, 238);
  padding-left: 170px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-right: 45px;
  outline: none;
}

// [placeholder]:focus::-webkit-input-placeholder {
//   transition: text-indent 0.4s 0.4s ease;
//   text-indent: -100%;
//   opacity: 1;
// }

.select_country {
  width: 120px;
}

input[type="checkbox"] {
  accent-color: #05A2AB;
}

.not_allow {
  pointer-events: auto;
  cursor: not-allowed !important;
}