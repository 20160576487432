.close_modal {
    position: absolute;
    right: 4%;
    top: 4%;
}




.button_show_member {
    width: 250px;
    text-align: center;
    // padding: 0 10px;
    right: 0;
    top: 15px;
    z-index: 9;

    animation-name: grow-box;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;

    @keyframes grow-box {
        0% {
            opacity: .2;
        }

        25% {
            opacity: .4;
        }

        50% {
            opacity: .6;
        }

        75% {
            opacity: .8;
        }

        100% {
            opacity: .9;
            width: 250px;
        }

    }
}

.triangle-up {
    right: 3px;
    bottom: 41px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 15px 7.5px;
    border-color: transparent transparent #EFF2F4 transparent;
}

.modal_show_member {
    width: 310px;
    // height: 300px;
    z-index: 99;
    border-radius: 15px;
}

.header_modal {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

}

.scroll {
    overflow-y: scroll;
}

.modal-vote-address{
    .dv-star-rating{
        .dv-star-rating-star{
            i{
                font-size: 50px;
            }
        }
    }
}

.item-last-member:last-child{
    border-bottom: none !important;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}