.photo-album-item {
  position: relative;

  .photo-item {
    position: relative;
    padding-bottom: 120%;
    overflow: hidden;
    cursor: pointer;

    .photo-item-preview {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
        border: 1px solid #f5f5f5;
      }
    }

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 4;
      display: block;
      width: 32px;
      height: 32px;
      margin: -16px 0 0 -16px;
      font-size: 2em;
      line-height: 64px;
      background-image: url(../../assets/images/icon/play.svg);
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-background-size: cover !important;
      background-size: cover !important;
      visibility: visible;
      cursor: pointer;
      opacity: 1;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      pointer-events: none;
    }
  }
}