.header_edit {
  bottom: 66px;
}

.header-extra {
  bottom: 65px;
}

.header-friend_edit {
  bottom: 65px;
}

.friends-menu {
  top: -35px;
}

.pending-friendsCount_icon {
  top: 17px;
  right: -11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  color: white;
  font-size: 12px;
  background-color: red;
  border-radius: 50%;
}

.shadow_verify {
  box-shadow: -1px 0px 14px 8px rgba(255,162,0,1);
  -webkit-box-shadow: -1px 0px 14px 8px rgba(255,162,0,1);
  -moz-box-shadow: -1px 0px 14px 8px rgba(255,162,0,1);
}