.avartar-shadow_verify {
    box-shadow: 0px 0px 6px 2px rgb(255, 162, 0);
    -webkit-box-shadow: 0px 0px 6px 2px rgb(255, 162, 0);
    -moz-box-shadow: 0px 0px 6px 2px rgb(255, 162, 0);
  }

  .img-1-1 {
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.text-break-word{
  word-break: break-word;
}

#category{
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
}

