.taggedUser-post_list {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.taggedUser-post_list:hover {
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

.button-option-post{
    padding: 5px;
}
.button-option-post:hover{
    border-radius: 50%;
    background: #eae8e8;
}

.item-select-option:hover{
  background: #eae8e8;
}
