.main_chat_content {
  padding: 20px 0px;
}

// .icon_search {
//     left: 62%;
// }
.dot_online {
  right: 0px;
  bottom: 6px;
}

.chat_item {
  width: 96%;
  // height: calc(100vh - 170px);
  // overflow-x: hidden;
  // overflow-y: auto;
}

.chat_msg {
  border-radius: 15px;
}

.icon_remove {
  right: 2%;
}

.box_info_chat {
  width: 40px;
}


.icon_search {
  position: absolute;
  right: 2%;
}

.search_input {
  width: 100%;
}

.shadow_verify {
  box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
  -webkit-box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
  -moz-box-shadow: -1px 0px 14px 8px rgba(255, 162, 0, 1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.section-select::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-select {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.message-item{

  .content-left{
    width: calc(100% - 120px);
    .content-left-right{
      width: calc(100% - 60px);
    }
  }
}

.section-message{
  .infinite-scroll-component{
    overflow: inherit !important;
    padding-bottom: 90px;
  }
}