.friendName-line_clamp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar-warp {
  width: 23.666667%;
}

.line-clamp {
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  &.line-clamp-1 {
    -webkit-line-clamp: 1;
  }
  &.line-clamp-2 {
    -webkit-line-clamp: 2;
  }
  &.line-clamp-3 {
    -webkit-line-clamp: 3;
  }
}

.avartar-shadow_verify {
    box-shadow: 0px 0px 6px 2px rgb(255, 162, 0);
    -webkit-box-shadow: 0px 0px 6px 2px rgb(255, 162, 0);
    -moz-box-shadow: 0px 0px 6px 2px rgb(255, 162, 0);
  }